import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
 
<path d="M2047 2030 c-8 -38 -57 -90 -86 -90 -11 0 -23 -4 -26 -10 -3 -6 4
-10 17 -10 33 0 76 -39 91 -84 l13 -38 13 41 c13 44 56 80 96 82 13 0 7 6 -20
18 -47 21 -63 38 -80 87 l-12 36 -6 -32z"/>
<path d="M1393 2039 c-75 -13 -163 -51 -231 -99 -58 -40 -134 -123 -155 -167
-8 -19 -18 -23 -52 -23 -56 0 -154 -19 -187 -36 -47 -24 -45 -72 4 -103 32
-20 41 -1 13 26 -23 24 -24 27 -9 44 8 9 37 22 62 28 55 11 142 22 142 17 0
-2 -7 -20 -15 -39 -17 -41 -19 -57 -6 -57 5 0 11 13 14 28 3 16 14 38 24 51
16 20 25 22 98 19 l80 -3 -40 -14 c-22 -8 -57 -25 -77 -37 l-37 -23 -4 32 -4
32 -1 -35 c-1 -20 -7 -41 -14 -48 -23 -23 12 -13 46 13 38 29 46 31 46 11 0
-8 5 -18 11 -22 8 -5 10 1 7 21 -5 24 -1 29 36 46 48 22 94 23 234 7 102 -12
102 -12 102 -39 0 -26 -3 -28 -47 -32 -27 -2 -3 -3 52 -3 55 0 79 1 53 3 -44
4 -48 6 -48 29 0 25 1 25 58 18 71 -8 75 -11 69 -40 -2 -13 0 -24 4 -24 5 0 9
12 9 26 0 22 4 25 23 21 12 -3 39 -9 60 -12 20 -4 37 -10 37 -14 0 -3 31 -7
69 -9 66 -3 69 -2 64 17 -7 27 7 27 41 0 35 -28 47 -15 37 38 -10 54 4 47 32
-16 28 -63 34 -35 6 31 -49 118 -152 229 -264 285 -95 49 -240 71 -342 52z
m87 -39 c0 -30 -1 -30 -54 -30 -49 0 -54 2 -49 20 4 15 14 20 37 20 17 0 38 5
46 10 20 13 20 14 20 -20z m90 10 c10 0 20 -9 23 -20 5 -18 0 -20 -49 -20 -52
0 -54 1 -54 26 0 24 3 26 31 20 17 -3 39 -6 49 -6z m-200 -12 c0 -25 -16 -35
-65 -43 l-50 -7 24 26 c13 13 35 27 50 29 14 2 29 5 34 6 4 0 7 -5 7 -11z
m320 -25 l25 -24 -50 7 c-58 8 -51 4 -59 31 -7 21 -5 22 26 17 18 -4 44 -17
58 -31z m-432 -12 c-13 -21 -88 -55 -88 -40 0 11 70 58 88 58 10 1 10 -3 0
-18z m527 -22 c33 -22 61 -42 63 -43 2 -2 -26 8 -61 21 -35 14 -69 34 -75 44
-20 31 7 23 73 -22z m-305 11 c0 -5 -10 -10 -22 -10 -13 0 -40 -3 -60 -6 -38
-7 -56 10 -20 19 33 9 102 7 102 -3z m127 -3 c13 -13 -3 -16 -60 -10 -74 8
-76 27 -2 21 30 -3 58 -8 62 -11z m-257 -6 c0 -5 -23 -14 -50 -20 -64 -14 -65
-14 -50 3 16 20 100 34 100 17z m370 -18 c13 -13 10 -14 -25 -8 -60 10 -75 15
-75 27 0 14 82 -1 100 -19z m-240 -13 c0 -13 -7 -20 -19 -20 -10 0 -39 -3 -64
-6 -43 -6 -45 -5 -40 14 4 17 15 22 52 25 25 2 52 4 59 5 6 1 12 -7 12 -18z
m85 10 c31 0 45 -5 49 -16 4 -9 5 -18 3 -20 -2 -2 -31 -1 -65 2 -53 6 -62 10
-62 26 0 14 5 18 16 14 9 -3 35 -6 59 -6z m-335 -8 c0 -4 -20 -15 -45 -24 -51
-19 -62 -5 -12 16 37 17 57 19 57 8z m114 -15 c-6 -17 -18 -22 -77 -33 -34 -6
-39 -4 -35 12 2 14 18 21 58 30 30 6 56 10 58 9 1 -1 0 -9 -4 -18z m394 -21
c4 -16 -1 -18 -35 -12 -61 11 -71 16 -76 35 -4 16 0 17 52 6 41 -8 57 -16 59
-29z m57 30 c51 -22 43 -36 -10 -17 -25 9 -45 19 -45 23 0 12 17 10 55 -6z
m-579 -41 c-5 -14 -21 -23 -73 -39 -40 -13 -15 24 31 45 44 19 52 18 42 -6z
m625 6 c7 11 14 8 36 -15 15 -16 21 -25 13 -21 -8 4 -3 -4 13 -19 37 -37 34
-53 -5 -27 -21 13 -33 29 -33 42 0 13 -6 24 -14 27 -9 3 -11 -2 -6 -18 6 -19
4 -21 -17 -15 -47 14 -73 31 -76 48 -3 17 -1 17 38 1 36 -14 43 -15 51 -3z
m-711 3 c0 -4 -8 -13 -17 -21 -10 -8 -12 -12 -5 -8 6 3 12 1 12 -5 0 -12 -48
-60 -61 -60 -4 0 -15 -7 -24 -16 -9 -9 -18 -14 -21 -11 -3 2 14 24 38 47 24
23 36 39 28 34 -8 -4 -4 5 9 19 23 26 41 34 41 21z m350 -49 l0 -55 -65 0 -65
0 0 50 0 49 63 4 c34 2 63 4 65 5 1 1 2 -23 2 -53z m91 45 l49 0 0 -50 0 -50
-65 0 -65 0 0 56 c0 47 3 55 16 50 9 -3 38 -6 65 -6z m-231 -59 c0 -48 0 -48
-37 -55 -21 -3 -73 -6 -115 -6 -76 0 -78 1 -78 24 0 19 9 27 40 40 57 23 62
21 54 -19 -4 -23 -2 -35 5 -35 6 0 11 17 11 40 0 36 3 40 33 49 17 5 44 10 60
10 26 1 27 -1 27 -48z m372 39 c34 -10 36 -13 40 -58 3 -26 4 -48 4 -48 -1 -1
-28 4 -61 10 l-60 12 -3 47 c-3 43 -1 47 20 47 13 0 39 -5 60 -10z m148 -99
c4 -33 2 -50 -5 -47 -5 3 -27 10 -47 17 -29 9 -38 18 -38 34 0 13 -3 37 -6 54
l-7 32 49 -19 c48 -19 49 -20 54 -71z m-763 2 c-3 -2 -18 -3 -34 -1 l-28 3 30
17 c26 14 30 14 33 1 2 -8 2 -17 -1 -20z m843 -19 c6 -14 10 -38 10 -54 l0
-29 -35 20 c-19 12 -35 29 -35 39 0 10 -3 32 -6 50 l-7 32 32 -16 c18 -10 36
-28 41 -42z m-460 5 c0 -18 -4 -20 -37 -15 -21 3 -63 9 -93 13 l-55 7 40 7
c22 3 64 7 93 8 47 1 52 -1 52 -20z m124 15 c9 -3 16 -17 16 -31 0 -24 -1 -24
-57 -17 -69 8 -73 10 -73 35 0 16 7 19 49 19 27 0 56 -3 65 -6z m126 -24 c18
-5 24 -13 23 -28 -2 -12 2 -22 7 -22 6 0 10 9 10 19 0 17 4 18 29 11 45 -12
71 -29 71 -45 0 -19 -6 -19 -132 7 -106 22 -108 23 -108 51 0 27 1 28 38 21
20 -4 48 -10 62 -14z"/>
<path d="M2180 1808 c0 -9 -8 -23 -18 -30 -16 -12 -16 -14 4 -34 l21 -21 22
23 c18 18 19 24 8 31 -8 4 -19 17 -25 28 -10 17 -11 17 -12 3z"/>
<path d="M1201 1693 c1 -10 5 -29 9 -43 7 -21 8 -19 9 18 1 23 -3 42 -9 42 -5
0 -10 -8 -9 -17z"/>
<path d="M855 1590 c-75 -30 -46 -99 42 -100 17 0 35 -5 39 -11 7 -12 -78 -8
-108 5 -16 7 -18 2 -18 -37 0 -42 2 -45 31 -51 81 -16 145 1 155 41 9 34 -11
52 -67 59 -27 4 -49 11 -49 16 0 11 64 6 93 -6 15 -7 17 -2 17 37 0 44 -1 44
-37 51 -49 8 -73 7 -98 -4z"/>
<path d="M1042 1502 c-17 -54 -32 -101 -32 -106 0 -4 19 -6 43 -4 35 2 43 7
45 26 5 31 32 29 32 -3 0 -23 4 -25 46 -25 40 0 45 2 39 19 -3 11 -18 57 -34
103 l-28 83 -39 3 -39 3 -33 -99z m78 -46 c-16 -17 -23 2 -15 41 l8 38 8 -35
c4 -20 4 -39 -1 -44z"/>
<path d="M1230 1495 l0 -105 36 0 37 0 -7 41 -7 42 41 -6 c35 -4 40 -3 40 14
0 16 -7 19 -40 19 -23 0 -40 5 -40 11 0 8 16 10 50 5 l50 -7 0 46 0 45 -80 0
-80 0 0 -105z"/>
<path d="M1410 1495 l0 -105 85 0 85 0 0 40 0 40 -55 0 c-33 0 -55 4 -55 11 0
6 15 9 34 8 19 -2 38 0 42 4 4 4 -11 7 -34 7 -23 0 -42 5 -42 10 0 6 23 10 50
10 l50 0 0 40 0 40 -80 0 -80 0 0 -105z"/>
<path d="M1633 1590 c-27 -11 -45 -43 -37 -65 8 -20 44 -35 84 -35 16 0 32 -5
36 -11 7 -12 -78 -8 -108 5 -16 7 -18 3 -18 -32 0 -48 4 -52 73 -59 67 -7 117
15 117 51 0 32 -22 48 -75 54 -25 2 -45 9 -45 13 0 12 52 11 83 0 24 -9 25 -8
29 30 2 22 1 42 -3 45 -16 9 -116 13 -136 4z"/>
<path d="M1800 1520 c0 -74 2 -83 26 -107 22 -22 32 -25 72 -20 68 7 82 30 82
128 l0 79 -34 0 -35 0 2 -57 c2 -56 1 -58 -23 -58 -24 0 -25 2 -23 58 l2 57
-35 0 -34 0 0 -80z"/>
<path d="M2000 1496 l0 -106 68 0 c37 0 78 6 92 13 28 14 38 51 20 73 -14 15
-56 19 -62 5 -5 -14 -51 -17 -55 -4 -3 7 9 13 29 16 l33 4 -32 1 c-18 1 -33 7
-33 12 0 14 57 13 62 -1 5 -16 46 -2 58 21 7 13 5 24 -8 40 -14 17 -32 22 -95
27 l-77 6 0 -107z"/>
<path d="M2210 1503 c1 -5 7 -19 14 -33 12 -21 12 -29 0 -52 -17 -33 -7 -36
24 -8 27 25 27 27 2 68 -18 29 -41 43 -40 25z"/>
<path d="M1407 1373 c-4 -3 -7 -12 -7 -20 0 -15 26 -18 34 -4 7 11 -18 33 -27
24z"/>
<path d="M1631 1366 c-12 -14 -5 -26 16 -26 15 0 18 26 4 34 -5 3 -14 0 -20
-8z"/>
<path d="M1780 1360 c0 -11 6 -20 14 -20 9 0 14 9 13 20 -1 11 -7 20 -14 20
-7 0 -13 -9 -13 -20z"/>
<path d="M1847 1354 c6 -16 34 -16 40 1 2 6 -8 12 -22 13 -18 1 -23 -2 -18
-14z"/>
<path d="M960 1320 c0 -6 14 -39 31 -75 61 -124 181 -228 319 -276 92 -33 257
-33 348 -1 82 29 179 91 230 146 54 59 95 125 88 142 -3 8 -2 13 3 10 8 -5 31
35 31 54 0 20 -17 9 -30 -17 l-13 -28 -9 28 c-9 24 -32 41 -25 18 1 -5 -14
-29 -33 -55 -34 -46 -34 -46 -53 -27 -20 19 -20 20 2 47 11 14 21 32 21 38 -1
10 -22 -13 -39 -42 -13 -23 -63 6 -56 32 5 19 5 19 -7 5 -12 -15 -17 -15 -38
-3 -31 17 -67 18 -67 2 0 -26 -42 -108 -55 -108 -34 1 -41 16 -29 63 13 55 13
57 2 57 -5 0 -12 -22 -16 -50 -8 -57 -10 -60 -47 -53 -25 5 -27 10 -31 57 l-3
51 -2 -51 c-2 -48 -4 -52 -29 -57 -37 -7 -40 -4 -47 48 -4 24 -12 47 -17 51
-10 6 -6 -25 11 -78 7 -23 5 -27 -18 -33 -32 -8 -48 10 -65 73 -12 45 -17 48
-66 31 -24 -9 -35 -8 -48 2 -14 12 -15 11 -9 -7 5 -16 0 -23 -21 -33 -21 -10
-28 -10 -34 1 -17 29 -38 52 -39 42 0 -6 10 -24 21 -38 22 -27 22 -28 2 -47
-19 -19 -19 -19 -54 28 -18 26 -33 51 -31 56 1 4 -2 7 -7 7 -12 0 -32 -60 -24
-73 4 -6 1 -7 -6 -3 -7 5 -10 13 -8 20 6 16 -10 56 -23 56 -5 0 -10 -5 -10
-10z m353 -60 c14 -33 22 -60 17 -60 -5 0 -15 -3 -23 -6 -11 -4 -27 11 -51 47
l-36 53 28 12 c15 7 30 13 33 13 3 1 17 -26 32 -59z m420 43 c23 -7 22 -10
-19 -66 -24 -33 -40 -47 -51 -43 -8 3 -18 6 -23 6 -5 0 4 27 18 60 20 45 31
59 42 54 8 -3 23 -9 33 -11z m-633 -105 c0 -25 -9 -22 -43 10 -24 22 -28 23
-19 6 15 -28 71 -77 127 -109 42 -25 64 -55 30 -41 -38 15 -46 14 -13 -2 21
-9 38 -20 38 -25 0 -4 -18 2 -40 13 -22 11 -39 24 -37 28 3 9 -40 52 -52 52
-21 0 -66 84 -68 129 l-3 46 39 -45 c22 -25 40 -53 41 -62z m849 56 c-5 -6 -7
-18 -4 -27 4 -9 -7 -31 -26 -53 -18 -22 -35 -41 -36 -43 -2 -2 -6 -1 -9 2 -4
4 10 25 30 47 43 47 48 65 8 27 -55 -53 -59 -16 -5 49 32 37 39 42 44 27 4
-10 3 -23 -2 -29z m-705 -21 c32 -47 34 -53 18 -62 -14 -8 -25 -1 -60 37 l-43
46 23 17 c12 10 23 17 24 16 1 -1 18 -25 38 -54z m561 16 c-3 -4 -22 -26 -42
-48 -29 -33 -39 -38 -54 -30 -17 9 -16 13 18 63 35 53 37 54 59 39 13 -8 21
-19 19 -24z m-609 -53 c34 -36 40 -47 30 -56 -11 -8 -21 -5 -42 12 -59 47 -68
49 -21 4 27 -25 47 -50 45 -56 -3 -10 -108 57 -108 69 0 11 39 71 46 71 4 0
26 -20 50 -44z m642 32 c18 -18 16 -22 -35 -62 -34 -26 -49 -33 -59 -25 -10 8
-3 20 29 54 48 49 49 49 65 33z m-414 -65 c19 -56 19 -53 1 -53 -12 0 -65 68
-65 84 0 4 20 11 42 15 3 0 13 -20 22 -46z m56 -4 c0 -63 -18 -62 -42 4 l-18
47 30 0 c29 0 30 -1 30 -51z m52 1 c-9 -27 -22 -50 -29 -50 -9 0 -13 15 -13
50 0 49 1 50 29 50 l29 0 -16 -50z m76 35 c9 -8 -50 -85 -64 -85 -9 0 -13 6
-10 13 2 6 10 29 17 50 10 31 16 36 33 31 12 -4 22 -8 24 -9z m-236 -48 c35
-54 18 -60 -33 -11 -21 21 -39 33 -39 27 0 -6 16 -25 36 -43 20 -17 33 -34 30
-37 -6 -6 -105 58 -106 68 0 6 60 37 75 38 6 1 22 -18 37 -42z m303 26 c19 -9
35 -19 35 -22 0 -10 -100 -74 -106 -68 -3 3 10 20 30 37 20 18 36 37 36 43 0
6 -20 -8 -44 -32 -24 -24 -46 -39 -49 -34 -6 10 43 93 55 93 4 0 23 -8 43 -17z
m195 -3 c0 -11 -84 -70 -100 -70 -20 0 -7 17 40 54 41 32 60 37 60 16z m-572
-62 c26 -18 49 -34 51 -35 14 -9 -10 -13 -26 -4 -67 36 -92 54 -87 62 8 13 11
12 62 -23z m434 17 c0 -12 -103 -67 -111 -59 -3 4 -1 9 6 11 6 2 29 17 50 33
38 28 54 33 55 15z m112 -22 c-11 -8 -18 -18 -17 -22 1 -3 -6 -7 -15 -7 -9 -1
-25 -5 -36 -9 -35 -15 -13 13 34 43 44 28 72 23 34 -5z m-510 -63 c-5 -4 -18
-11 -29 -14 -13 -3 -11 -4 6 -2 17 2 30 -4 38 -16 10 -16 8 -18 -31 -17 -40 1
-41 2 -13 9 l30 8 -36 1 c-31 1 -90 30 -64 31 6 0 3 4 -5 10 -27 17 -4 20 33
5 20 -8 42 -15 49 -14 7 0 -11 9 -39 20 -30 12 -53 27 -53 35 0 11 15 6 61
-17 34 -17 58 -34 53 -39z m416 56 c0 -8 -23 -23 -52 -35 -29 -11 -47 -20 -40
-20 7 -1 29 6 49 14 28 12 38 13 44 3 6 -9 13 -9 33 0 36 16 33 6 -4 -13 -17
-9 -30 -11 -30 -5 0 5 -4 10 -10 10 -5 0 -9 -5 -7 -12 1 -7 -17 -17 -43 -24
-39 -10 -41 -12 -20 -16 15 -3 8 -5 -20 -6 -25 0 -39 2 -32 5 6 2 12 10 12 17
0 8 9 12 23 9 12 -2 33 0 47 6 21 9 18 9 -16 5 -64 -9 -63 11 3 45 69 36 63
34 63 17z m-181 -14 c74 -37 61 -81 -31 -103 -92 -22 -210 23 -182 69 30 49
148 68 213 34z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
